<template>
    <div>
        <div class="container" v-if="decreeGosoTemplatesId">
            <div class="row">

                <div class="col-md-12 mt-2 text-center">
                    <h4>Шаблон Госо</h4>
                </div>

                <div class="form-group row mt-4">
                    <label for="study_level" class="col-md-3 col-form-label">Выберите уровень обучения</label>
                    <div class="col-md-9">
                        <select class="form-control form-select" name="study_level" id="study_level"
                                @change="changeStudyLevel">
                            <option v-for="(item, index) in gosoTemplate_form.studyLevels"
                                    :selected="gosoTemplate_form.selectedStudyLevelId==item.id"
                                    :value="item.id"
                                    :key="index">{{ item.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-12 mt-5 mb-5">
                    <button type="button" class="btn btn-primary" @click="addRowGosoTemplate">
                        <i class="fas fa-plus-circle" aria-hidden="true"></i>
                        &nbsp;Добавить цикл
                    </button>
                </div>


                <div class="form-group row card mb-4 border border-5"
                     v-for="(item, index) in gosoTemplate_form.gosoTemplateInfos.filter(i => i.parent_id == 0)"
                     :key="index">

                    <div class="goso-cycle-remove-item__button d-flex flex-row-reverse">
                        <i class="fas fa-trash-alt" @click="deleteRowGosoTemplate(item.id)"></i>
                    </div>
                    <div class="card-body">
                        <div>
                            <strong class="p-1 rounded bg-sky-blue">
                                Количество кредитов в цикле: {{ getChildCreditsCount(item.id) }}
                            </strong>
                        </div>


                        <div class="row">
                            <div class="col-md-8 mt-4">
                                <label for="goso_cycle_name_id">Название цикла</label>
                                <select class="form-control form-select" id="goso_cycle_name_id"
                                        @input="changeGosoTemplateData(item, 'goso_cycle_name_id', $event)">
                                    <option v-for="(cycle, index) in gosoTemplate_form.cycleNames" :value="cycle.id"
                                            :key="index"
                                            :selected="cycle.id == item.goso_cycle_name_id">{{ cycle.name }}
                                    </option>
                                </select>
                            </div>
                            <!--                                <div class="col-md-4 mt-4">-->
                            <!--                                    <label for="credit">Количество кредита</label>-->
                            <!--                                    <input type="number" id="credit" class="form-control" placeholder="Кредит"-->
                            <!--                                           :value=item.credit-->
                            <!--                                           @change="changeGosoTemplateData(item,'credit', $event)">-->
                            <!--                                </div>-->

                        </div>
                        <div class="col-md-12 mt-4">
                            <label for="note">Примечание</label>
                            <textarea type="text" id="note" class="form-control" placeholder="Примечание"
                                      :value=item.note
                                      @change="changeGosoTemplateData(item,'note', $event)"></textarea>
                        </div>

                        <!--Child компонент BEGIN-->
                        <div class="form-group row card mb-4 mt-3 bg-light text-dark"
                             v-for="(childItem, childIndex) in gosoTemplate_form.gosoTemplateInfos.filter(i => i.parent_id == item.id)"
                             :key="childIndex">

                            <div class="goso-cycle-remove-item__button d-flex flex-row-reverse">
                                <i class="fas fa-trash-alt" @click="deleteRowGosoTemplate(childItem.id)"></i>
                            </div>

                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-8 mt-4">
                                        <label for="child_goso_cycle_name_id">Название компонента</label>
                                        <select class="form-control form-select" id="child_goso_cycle_name_id"
                                                @input="changeGosoTemplateData(childItem, 'goso_components_name_id', $event)">
                                            <option v-for="(cycle, index) in gosoTemplate_form.componentNames"
                                                    :value="cycle.id" :key="index"
                                                    :selected="cycle.id == childItem.goso_components_name_id">{{
                                                cycle.name
                                                }}
                                            </option>
                                        </select>

                                    </div>
                                    <div class="col-md-4 mt-4">
                                        <label for="child_credit">Количество кредита</label>
                                        <input type="number" id="child_credit" class="form-control"
                                               placeholder="Кредит"
                                               :value=childItem.credit
                                               @change="changeGosoTemplateData(childItem,'credit', $event)">
                                    </div>
                                </div>
                                <div class="col-md-12 mt-4">
                                    <label for="child_note">Примечание</label>
                                    <textarea type="text" id="child_note" class="form-control"
                                              placeholder="Примечание"
                                              :value=childItem.note
                                              @change="changeGosoTemplateData(childItem,'note', $event)"></textarea>
                                </div>

                            </div>
                            <hr/>
                            <!--Child компонент END-->

                        </div>

                    </div>
                    <div class="card-footer">
                        <div class="col-md-2">
                            <button type="button" class="btn btn-primary"
                                    @click="addRowGosoTemplateComponent(item)">
                                <i class="fas fa-plus-circle" aria-hidden="true"></i>&nbsp;Добавить компонент
                            </button>
                        </div>
                    </div>
                    <hr/>


                </div>

            </div>
            <div class="col-md-12 mt-4 mb-5 text-center" style="min-height: 120px">
                <button type="button" class="btn btn-primary"
                        @click="updateGosoTemplateData">
                    Сохранить
                </button>
            </div>

            <div class="footer">
                <div class="row">
                    <div class="col-md-3 border border-gray"
                         v-for="(cycle, cycleIdx) in goso" :key="'cycle' + cycleIdx">
                        <div class="row">
                            <div class="col-md-4 ps-3">
                                <strong>{{ cycle.cycleName }}</strong>
                            </div>

                            <div class="col-md-8" v-if="cycle.components.length">
                                <div class="row p-0" v-for="(component, comIdx) in cycle.components"
                                     :key="'component' + comIdx">
                                    <div class="col-6 col-sm-6 col-md-6 border border-gray">{{ component.name }}</div>
                                    <div class="col-6 col-sm-6 col-md-6 border border-gray">{{ component.credit }}</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="text-left p-1">
                    <strong>Итого: {{ allCreditsCount }}</strong>
                </div>
            </div>

        </div>
        <div class="container mt-4 text-center" v-else>
            <h3>Не выбран приказ</h3>
        </div>
    </div>

</template>

<script>
    import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

    export default {
        name: "GosoTemplates",
        data() {
            return {
                decreeGosoTemplatesId: this.$route.query.id || 0
            }
        },
        computed: {
            ...mapState('gosoTemplates', ['gosoTemplate_form']),
            allCreditsCount() {
                if (!this.gosoTemplate_form) {
                    return 0
                }
                return this.gosoTemplate_form.gosoTemplateInfos.filter(i => i.parent_id != 0).reduce((sum, item) => +item.credit + sum, 0)
            },
            goso() {
                if (!this.gosoTemplate_form || !this.gosoTemplate_form.cycleNames.length) {
                    return 0
                }
                return this.gosoTemplate_form.gosoTemplateInfos
                    .filter((cycle) => cycle.parent_id == 0)
                    .map((cycle) => {
                        let cycleName = this.gosoTemplate_form.cycleNames.find((i) => i.id == cycle.goso_cycle_name_id);
                        cycleName = cycleName ? cycleName.short_name : ''
                        return {
                            cycleName,
                            components: this.gosoTemplate_form.gosoTemplateInfos
                                .filter((component) => component.parent_id == cycle.id)
                                .map((component) => {
                                    let componentName = this.gosoTemplate_form.componentNames.find((i) => i.id == component.goso_components_name_id);
                                    componentName = componentName ? componentName.short_name : ''
                                    return {
                                        name: componentName,
                                        credit: component.credit
                                    }
                                })
                        }
                    })
            }
        },
        methods: {
            ...mapActions('gosoTemplates', ['GET_STUDY_LEVEL', 'GET_GOSO_CYCLE_NAME', 'GET_GOSO_COMPONENTS_NAME', 'POST_GOSO_TEMPLATE_DATA',
                'GET_GOSO_TEMPLATE_DATA', 'DELETE_GOSO_TEMPLATE_DATA', 'PUT_GOSO_TEMPLATE']),

            ...mapMutations('gosoTemplates', ['SET_STUDY_LEVEL_ID', 'ADD_ROW_GOSO_TEMPLATE_INFO', 'DELETE_ROW_GOSO_TEMPLATE_INFO',
                'DELETE_CHILD_ROW_GOSO_TEMPLATE_INFO', 'ADD_ROW_GOSO_TEMPLATE_COMPONENT', 'UPDATE_GOSO_TEMPLATE_DATA', 'SET_DECREE_GOSO_TEMPLATES_ID']),

            async addRowGosoTemplate() {
                this.gosoTemplate_form.decreeGosoTemplatesId = this.decreeGosoTemplatesId
                await this.PUT_GOSO_TEMPLATE()
                let data = await this.POST_GOSO_TEMPLATE_DATA();
                if (data.success) {
                    await this.GET_GOSO_TEMPLATE_DATA();
                }
            },
            async deleteRowGosoTemplate(id) {
                await this.PUT_GOSO_TEMPLATE()
                await this.DELETE_GOSO_TEMPLATE_DATA(id)
                await this.GET_GOSO_TEMPLATE_DATA();
                // this.DELETE_ROW_GOSO_TEMPLATE_INFO(id);
            },
            async deleteChildRowGosoTemplate(item) {
                await this.PUT_GOSO_TEMPLATE()
                await this.DELETE_CHILD_ROW_GOSO_TEMPLATE_INFO(item);
            },
            async addRowGosoTemplateComponent(item) {
                this.gosoTemplate_form.decreeGosoTemplatesId = this.decreeGosoTemplatesId
                await this.PUT_GOSO_TEMPLATE()
                let data = await this.POST_GOSO_TEMPLATE_DATA(item.id);
                if (data.success) {
                    await this.GET_GOSO_TEMPLATE_DATA();
                }
            },
            async changeStudyLevel(e) {
                let studyLevelId = e.target.value
                await this.SET_STUDY_LEVEL_ID(studyLevelId)
                await this.GET_GOSO_CYCLE_NAME();
                await this.GET_GOSO_COMPONENTS_NAME();
                await this.GET_GOSO_TEMPLATE_DATA();
            },
            changeGosoTemplateData(item, property, e, val = 'value') {
                const value = e.target[val]
                this.UPDATE_GOSO_TEMPLATE_DATA({item, property, value})
            },
            async updateGosoTemplateData() {
                await this.PUT_GOSO_TEMPLATE().then(res => {
                    if (res.success == true) {
                        this.GET_GOSO_TEMPLATE_DATA()
                        this.$message({title: 'Добавление', text: 'Данные успешно добавились'})
                    } else {
                        const errorText = res.errors[0].map(err => err.message).join('\n');
                        this.$error({title: 'Добавление', text: errorText})
                    }
                })
            },
            getChildCreditsCount(parent_id) {
                return this.gosoTemplate_form.gosoTemplateInfos.filter(i => i.parent_id == parent_id).reduce((sum, item) => +item.credit + sum, 0)
            }
        },
        async mounted() {
            if (this.decreeGosoTemplatesId) {
                await this.SET_DECREE_GOSO_TEMPLATES_ID(this.decreeGosoTemplatesId)
                await this.GET_STUDY_LEVEL();
                await this.GET_GOSO_CYCLE_NAME();
                await this.GET_GOSO_COMPONENTS_NAME();
                await this.GET_GOSO_TEMPLATE_DATA();
            }
        }
    }
</script>
<style scoped>
    .bg-sky-blue {
        background-color: #B7D4FF;
    }

    .footer {
        position: fixed;
        left: 0;
        bottom: 0;
        background-color: #444;
        color: #fff;
        width: 100%;
        padding-left: 5px;
    }

    .border-gray {
        border-color: #999 !important;
    }
</style>